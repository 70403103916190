import React from 'react';
import { SText } from 'atoms/text/Text';
import { CheckBoxProps } from 'custom-types/design/molecules/CheckBox';
import { ColorWhite } from 'theme';
import { Check, CheckBoxWrapper } from './CheckBox.components';

const CheckBox: React.FC<CheckBoxProps> = ({isChecked, text, onChange, onClickText}) => {
  return (
    <CheckBoxWrapper>
        <Check 
          checked={isChecked} 
          onChange={onChange}
        />
        <SText
            onClick={onClickText}
            color={ColorWhite}
            style={{fontStyle: 'italic', cursor: 'pointer'}}
        >
            {text}
        </SText>
    </CheckBoxWrapper>
  )
}

export default CheckBox