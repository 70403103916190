import { useTranslation } from "react-i18next";
import { LegalLinksProps } from "./Footer.types";
import { LanguageCodes } from "molecules/selectLanguage/SelectLanguage.types";

export const useFooterController = () => {
  const {i18n, t} = useTranslation();
  const languageCode = i18n.language as LanguageCodes;

  const legalLinks: LegalLinksProps[] = [
    {
      label: t("footer.legalLinks.privacyPolicy"),
      link: languageCode === LanguageCodes.ES ? process.env.REACT_APP_LINK_REYCO_PRIVACY_POLICY_ES : process.env.REACT_APP_LINK_REYCO_PRIVACY_POLICY_EN
    },
  ];

  return {
    legalLinks,
  };
};